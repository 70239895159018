export const environment = {
  showGitInfoInTitle: false,
  envName: 'envProd',
  reduxMaxStoreAge: 20,
  baseUrl: 'npa/api',
  production: true,
  pathToLdeScriptsNpa: '/lde-api',
  pathToLdeScriptsLpa: '/lde-api-local',
  enableMockUser: false,
  ravenDns: 'https://f1dee3b8c3134f5c8627874438dab920@aissd.mos.ru:9001/1',
  sentryEnabled: false,
  loadLde: true,
  enableRRWeb: true,
  enableAnalytics: true,
  analyticsUrl: 'https://matomo-digitalofficial.mos.ru/',
  analyticsSiteId: 3,
  hpsmBaseUrl: 'npa/hpsm/api',
  needJWT: false,
  authBaseUrl: '/auth',
  enableContentSecurityPolicy: true,
};
