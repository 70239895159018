import { DocumentsControlAlternatives } from '@appDocuments/documents-route/models/documents-control-alternatives.model';
import {
  DocumentControlAlternativesActions,
  DocumentControlAlternativestActionTypes,
} from '@appDocuments/documents-route/store/document-control-alternatives/document-control-alternatives.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface DocumentsControlAlternativesByPhase {
  phaseId: number;
  documents: DocumentsControlAlternatives[];
  isAlternativesOrRemarksExist: boolean;
}
export interface DocumentsControlAlternativesState extends EntityState<DocumentsControlAlternativesByPhase> {
  status: StoreLoadStatus;
  error: any;
  isAlternativesOrRemarksExist: boolean;
}

const adapter = createEntityAdapter<DocumentsControlAlternativesByPhase>({
  selectId: (e) => e.phaseId,
});

export const initialState: DocumentsControlAlternativesState = adapter.getInitialState({
  status: null,
  error: null,
  isAlternativesOrRemarksExist: null,
});

export function documentsControlAlternativesReducer(
  state = initialState,
  action: DocumentControlAlternativesActions
): DocumentsControlAlternativesState {
  switch (action.type) {
    case DocumentControlAlternativestActionTypes.Reset:
      return { ...initialState };
    case DocumentControlAlternativestActionTypes.GetDocumentControlAlternatives:
    case DocumentControlAlternativestActionTypes.GetDocumentsMainBlockControlAlternatives:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
        error: null,
      };

    case DocumentControlAlternativestActionTypes.GetDocumentControlAlternativesSuccess: {
      return adapter.upsertMany(action.payload, {
        ...state,
        status: StoreLoadStatus.loaded,
        error: null,
      });
    }

    case DocumentControlAlternativestActionTypes.GetDocumentControlAlternativesError: {
      return {
        ...state,
        status: StoreLoadStatus.error,
        error: action.payload,
      };
    }

    case DocumentControlAlternativestActionTypes.GetDocumentControlAlternativesOrRemarksResult: {
      return {
        ...state,
        isAlternativesOrRemarksExist: action.payload,
      };
    }

    case DocumentControlAlternativestActionTypes.SetDocumentControlAlternativesStatus: {
      return {
        ...state,
        status: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const {
  selectAll: selectAllDocuments,
  selectTotal: selectDocumentsCount,
  selectEntities: selectDocumentEntities,
} = adapter.getSelectors();
