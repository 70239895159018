import { Injectable, NgZone } from '@angular/core';
import { UserInfoResponseModel } from '@models/user-info.model';
import { CompatClient, Frame, Message, Stomp } from '@stomp/stompjs';
import { Subject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { getUserInfo } from '../../store/user-info';
import { ReduxService } from '../redux/redux.service';
import { SocketChannel } from './models/socket-channel';

/**
 * Сервис для работы с websocket
 * При инициализации подключается к бэкэнду по вебсоккету
 * и предоставляет клиентам возможность подписки на уведомления от сервера
 */
@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  // константы
  /** Точка входа для подключения SockJs */
  private ENTRY_POINT_URL = `/socket/sockJs`;
  /** Время через которое клиент и сервер обмениваются пингами для подтверждения соединения*/
  private HEARTBEAT = {
    incoming: 5000,
    outgoing: 5000,
  };
  /** Задержка между попытками повторного подключения */
  private RECONNECT_DELAY = 5000;

  /** StompClient - клиент реализующий протокол Stomp */
  private stompClient: CompatClient;

  /** Флаг для включения отладочного вывода */
  private debug = false;

  /** Мапа для сопоставления адреса с уже существующей подпискойno
   * Нужна для восстановления подписок при реконнекте и кеширования подписок
   */
  private subjectsMap: { [key: string]: Subject<any> } = {};

  constructor(private redux: ReduxService, private ngZone: NgZone) {
    // Подключение по websocket должно быть привязано к пользователю для корректной работы
    // поэтому выбираем текущего пользователя.
    this.redux.selectStore(getUserInfo).subscribe((userData: UserInfoResponseModel) => {
      if (!userData || !userData.currentUser || !userData.currentUser.employee) {
        return;
      }

      this.initializeWebSocketConnection();
    });
  }

  /**
   * Выбор канала для подписки
   * @param address адрес канала (Например: /user/notifications)
   * Если канал начинается с /user, то по нему будут
   * отправляться персональные уведомления для текущего пользователя
   * иначе - все сообщения независимо от пользователя
   * @returns subject для подписки на соообщения
   */
  public selectChanel<T>(chanel: SocketChannel<T>): Subject<T> {
    const address = chanel.address;
    if (this.subjectsMap[address]) {
      return this.subjectsMap[address];
    }

    const result = new Subject<T>();
    this.subjectsMap[address] = result;
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.subscribe(address, (message) => {
        result.next(this.convertMessage(message));
      });
    }

    return result;
  }

  /**
   * Метод для отправки сообщений через webSocket
   * @param dest - адрес назначения (например /app/hello)
   * @param message - сообщение для отправки
   */
  public sendMessage(dest: string, message: any): void {
    const stringMessage = JSON.stringify(message);
    this.stompClient.send(dest, {}, stringMessage);
  }

  private initializeWebSocketConnection(): void {
    this.tryConnect();
  }

  private tryConnect(): void {
    this.ngZone.runOutsideAngular(() => {
      // инициализируем Stomp через созданный ранее канал
      this.stompClient = Stomp.over(() => new SockJS(this.ENTRY_POINT_URL));
      this.stompClient.reconnectDelay = this.RECONNECT_DELAY;

      // Настраиваем stomp
      this.stompClient.heartbeat = this.HEARTBEAT;
      if (!this.debug) {
        this.stompClient.debug = () => {};
      }

      // Коннектимся
      this.stompClient.connect({}, this.onConnect.bind(this), this.onError.bind(this));
    });
  }

  /** Обработчик успешного подключения
   * Пока что только инициализирует подписки
   */
  private onConnect(frame: Frame): void {
    this.initSubscriptions();
  }

  /** Обработчик ошибок при передаче
   * Ошибкой может быть только потеря связи, поэтому в случае ошибки планируется реконнект через заданное время.
   */
  private onError(error: string): void {
    console.log(`Scheduled reconnect after ${this.RECONNECT_DELAY}ms`);
  }

  /** Функция для инициализации подписок
   * Если вызывается при первом подключении то подписок нет и нечего инициализированно не будет
   * Если вызывается после реконнекта то будут восстановлены все старые подписки
   */
  private initSubscriptions(): void {
    Object.keys(this.subjectsMap).forEach((dest) => {
      this.stompClient.subscribe(dest, (message) => {
        this.subjectsMap[dest].next(this.convertMessage(message));
      });
    });
  }

  /** Функция для приведения сообщений к одному формату */
  private convertMessage(message: Message): any {
    return JSON.parse(message.body);
  }
}
