import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { CoreModule } from '@appCore/core.module';
import { NotificationsService } from '@appCore/services/notifications/notifications.service';
import { ThirdPartyService } from '@appCore/services/third-party/third-party.service';
import { isShowErrorPopup } from '@appCore/store/error-message';
import { JwtAuthTokenService } from '@appShared/services/jwt-auth-token.service';
import { SettingsHelperService } from '@appShared/services/settings-helper.service';
import { SharedModule } from '@appShared/shared.module';
import { environment } from '@environments/environment';
import { RRWebService } from '@libs/projects/component-lib/src/lib/services/rrweb.service';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import * as Sentry from '@sentry/browser';
import { RrwebTypesCodes } from '@sharedEnums/rrweb-types-codes.enum';
import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { DelegatingService } from './core/services/delegeting/delegating.service';
import { GitInfoService } from './core/services/git-info/git-info.service';
import { ReduxService } from './core/services/redux/redux.service';
import { getUserInfo, getUserInfoStatus } from './core/store/user-info';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [SharedModule, CoreModule, NgIf, RouterOutlet, AsyncPipe],
  standalone: true,
})
export class AppComponent implements OnInit {
  public showGitInfoInTitle: boolean;
  public showLoader: Observable<boolean>;
  public errorMessage$: Observable<string>;
  public navigationToggleLoader: boolean;
  public isVersionUpdated$: Observable<boolean>;

  /**
   * @param title заголовок страницы
   * @param gitInfo инфа из гита
   * @param redux редакс
   * @param router Сервис для манипуляций с url
   * @param notifications Сервис для c работы с уведомлениями
   * @param thirdParty Сервис для c работы с встроенным редактором
   * @param rrweb Сервис RRWebService
   * @param delegatingService Сервис DelegatingService
   * @param settingsService Сервис хелпер настроек
   * @param jwtAuthTokenService Сервис для раоботы с jwt аутентификацией
   */
  constructor(
    private title: Title,
    private gitInfo: GitInfoService,
    private redux: ReduxService,
    private router: Router,
    private notifications: NotificationsService,
    private thirdParty: ThirdPartyService,
    private rrweb: RRWebService,
    private delegatingService: DelegatingService,
    private settingsService: SettingsHelperService,
    private jwtAuthTokenService: JwtAuthTokenService,
    @Inject(DOCUMENT) private documentRef: Document,
    private meta: Meta
  ) {
    if (environment.needJWT) {
      this.jwtAuthTokenService.checkJwtAuthToken();
    }
  }

  ngOnInit(): void {
    this.updateContentSecurityPolicy();
    this.setRavenContext();
    this.subscribeToNavigationEvents();
    this.notifications.getNotifications();

    this.isVersionUpdated$ = this.gitInfo.checkIsVersionUpdated();
    this.showGitInfoInTitle = environment.showGitInfoInTitle;
    this.showLoader = this.getShowLoader();
    moment.locale('ru');

    if (environment.showGitInfoInTitle) {
      this.addGitVersionToPageTitle();
    }

    /** сообщение об ошибке */
    this.errorMessage$ = this.redux.selectStore(isShowErrorPopup);

    this.delegatingService.subscribeToDelegatingEvents();
    this.settingsService.getSettings();
    this.loadAbstractGraphScript();
  }

  private updateContentSecurityPolicy(): void {
    if (environment.enableContentSecurityPolicy) {
      const dataSource = `'self' data: *.mos.ru`;
      this.meta.addTag({
        ['http-equiv']: 'Content-Security-Policy',
        content: `img-src ${dataSource}; media-src ${dataSource}`,
      });
    }
  }

  private loadAbstractGraphScript(): void {
    const body = this.documentRef.body;
    const script = this.documentRef.createElement('script');
    script.src = './assets/js/vis.min.js';
    const graphAccessEnvironments = ['dev', 'test'].includes(environment.envName);
    if (graphAccessEnvironments) {
      body.appendChild(script);
    }
  }

  /** Показываем лаудер пока не загружен пользователь */
  private getShowLoader(): Observable<boolean> {
    return this.redux.selectStore(getUserInfoStatus).pipe(map((i) => i === StoreLoadStatus.inProgress));
  }

  /** Добавление версии в заголовок */
  private addGitVersionToPageTitle(): void {
    const currentTitle = this.title.getTitle();
    this.gitInfo.getVersionAsString().subscribe((version) => {
      this.title.setTitle(`${currentTitle} ${version}`);
    });
  }

  private subscribeToNavigationEvents(): void {
    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationStart))
      .subscribe(() => setTimeout(() => (this.navigationToggleLoader = true)));
    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationEnd || ev instanceof NavigationCancel))
      .subscribe(() => setTimeout(() => (this.navigationToggleLoader = false)));
  }

  private setRavenContext(): void {
    this.redux
      .selectStore(getUserInfo)
      .pipe(first((i) => !!i))
      .subscribe((userInfo) => {
        const employee = userInfo.currentUser.employee;
        const userRoles = userInfo.currentUser.roles;

        /** отложенное подключение редактора  */
        this.thirdParty.connectLDE(userRoles);

        Sentry.setTags({
          env: environment.envName,
          employeeName: `${employee.lastName} ${employee.firstName} ${employee.patronymic}`,
        });
        Sentry.setUser({
          userId: employee.id,
          email: employee.email,
          username: `${employee.lastName} ${employee.firstName} ${employee.patronymic}`,
        });

        if (environment.enableRRWeb) {
          this.rrweb.initRrweb(userInfo, RrwebTypesCodes.agreementOfDocument);
        }
      });
  }
}
